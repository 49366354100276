<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="!isDirty"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="content-move-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'CONTENT MOVE'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <div v-if="showMainSection" id="content-move-main" class="collapsable-section">
          <h2 class="subheader">
            Build Content Move
          </h2>
          <div class="row">
            <md-radio v-model="applyToType" value="all" @change="isDirty = true">
              Apply on All Folders
            </md-radio>
            <md-radio v-model="applyToType" value="single" @change="isDirty = true">
              Apply on Folder
            </md-radio>
          </div>
          <div v-show="applyToSingleFolder" class="row">
            <VasionCheckbox
              id="include-subfolder"
              name="include-subfolder"
              class="export-checkbox"
              :checked="includeSubfolders"
              @change="toggleCheckbox('includeSubfolders')"
            >
              Include Subfolders
            </VasionCheckbox>
          </div>
          <div class="row">
            <VasionInput
              v-show="applyToSingleFolder"
              id="folder-name"
              v-model="folderName"
              class="row-field browse-input"
              title="FOLDER"
              inputType="top-white"
              name="folder-name"
              :width="'306'"
            />
            <VasionButton
              v-show="applyToSingleFolder"
              id="browse"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('folder')"
            >
              Browse
            </VasionButton>
            <VasionInput
              id="destination-folder"
              v-model="destinationFolder"
              class="row-field browse-input"
              title="DESTINATION FOLDER"
              inputType="top-white"
              name="destination-folder"
              placeholder="Select Folder..."
              :width="'306'"
            />
            <VasionButton
              id="browse-button"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog"
            >
              Browse
            </VasionButton>
          </div>
          <div class="row">
            <VasionCheckbox
              id="new-subfolder"
              name="new-subfolder"
              class="export-checkbox"
              :checked="newSubfolder"
              @change="toggleCheckbox('newSubfolder')"
            >
              Create New Subfolder
            </VasionCheckbox>
          </div>
          <div v-show="newSubfolder" class="row">
            <VasionInput
              id="new-subfolder-name"
              v-model="newSubfolderName"
              class="row-field browse-input"
              title="FOLDER"
              inputType="top-white"
              name="new-subfolder-name"
              placeholder="Please enter a name"
              :width="'306'"
              @input="isDirty = true"
            />
          </div>
          <div v-show="folderName" class="row">
            <VasionCheckbox
              id="move-to-folder-and-contents"
              name="move-to-folder-and-contents"
              class="export-checkbox"
              :checked="moveSubfolder"
              @change="toggleCheckbox('moveSubfolder')"
            >
              Move Selected Subfolder and Its Contents
            </VasionCheckbox>
          </div>
          <div class="row">
            <VasionCheckbox
              id="move-with-ocr"
              name="move-with-ocr"
              class="export-checkbox"
              :checked="moveWithOCR"
              @change="toggleCheckbox('moveWithOCR')"
            >
              Move Documents with OCR Only
            </VasionCheckbox>
          </div>
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="attributeForm"
              class="row-field"
              :dataArray="attributeFormList"
              width="396"
              :title="`${$formsLabel.toUpperCase()}`"
              :placeholder="`Select ${$formsLabel}...`"
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-show="attributeForm" class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="compareField"
              class="row-field"
              :dataArray="attributeFieldList"
              width="396"
              title="INDEX FIELDS"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-show="compareField.name"
              v-slot="slotItem"
              v-model="compareFieldCriteria"
              class="row-field"
              :dataArray="comparisonFormList"
              width="396"
              title="COMPARING CRITERIA"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionInput
              v-show="compareField.name"
              id="comparing-value"
              v-model="comparingValue"
              class="row-field browse-input"
              title="COMPARING VALUE"
              inputType="top-white"
              name="comparing-value"
              :width="'306'"
              @input="isDirty = true"
            />
          </div>
          <div v-show="attributeForm" class="row">
            <VasionCheckbox
              id="create-dynamic-subfolders"
              name="create-dynamic-subfolders"
              class="export-checkbox"
              :checked="createDynamicSubfolders"
              @change="toggleCheckbox('createDynamicSubfolders')"
            >
              Create Dynamic Subfolders Based on Values
            </VasionCheckbox>
          </div>
          <div v-show="createDynamicSubfolders && attributeForm && attributeForm.value > 0" class="row align-values">
            <VasionDropList
              v-slot="slotItem"
              v-model="dynamicField"
              class="row-field"
              :dataArray="attributeFieldList"
              width="396"
              title="INDEX FIELDS"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              name="add-complete-email-template-button"
              :icon="'VasionAddIcon'"
              title="Add Dynamic Field"
              class="configure-template-icon-button"
              @vasionButtonClicked="addIndexField()"
            />
          </div>
          <div v-show="createDynamicSubfolders && attributeForm && attributeForm.value > 0" class="row">
            <div class="grid-div vasion-html-table-default table-width">
              <table>
                <thead>
                  <tr>
                    <th>
                      <label class="vasion-html-table-header">Dynamic Subfolder Structure</label>
                    </th>
                    <th class="image-column-end" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(field, index) in dynamicSubFolders"
                    :key="index"
                    :class="{'vasion-lightest-grey-background': index % 2 === 0}"
                  >
                    <td>
                      <label class="vasion-html-table-field">{{ field.name }}</label>
                    </td>
                    <td class="image-column-end">
                      <VasionButton
                        name="add-complete-email-template-button"
                        :icon="'VasionRemoveIcon'"
                        title="Remove Dynamic Field"
                        class="configure-template-icon-button"
                        @vasionButtonClicked="removeIndexField(field.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          @vasionButtonClicked="folderDialogOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'EditContentMove',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      applyToType: 'all',
      attributeForm: '',
      compareField: '',
      compareFieldCriteria: '',
      comparingValue: '',
      createDynamicSubfolders: false,
      dynamicField: '',
      dynamicSubFolders: [],
      fileBrowseField: '',
      folderId: 0,
      folderName: '',
      destinationFolder: '',
      destinationFolderId: 0,
      includeSubfolders: false,
      isDirty: false,
      moveSubfolder: false,
      moveWithOCR: false,
      newSubfolder: false,
      newSubfolderName: '',
      schedulerId: 0,
      schedulerTitle: '',
      showBrowseFoldersDialog: false,
      showContentMove: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      snackbarImage: false,
      snackbarTitle: '',
      snackbarText: '',
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    applyToAllFolders() { return this.applyToType === 'all' },
    applyToSingleFolder() { return this.applyToType === 'single' },
    attributeFieldList() { return this.$store.state.common.indexFields },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    comparisonFormList() {
        return this.$store.state.automationEngine.comparisonForm.map((c) => {
            return {
                name: c.sName,
                value: c.iID,
            }
        })
    },
    dynamicSubFoldersData() {
        return this.dynamicSubFolders.map((f) => {
            return f.value
        })
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
  },
  watch: {
    attributeForm: function () {
      if (this.attributeForm && this.attributeForm.value > 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', this.attributeForm.value)
      }
    },
  },
  async created() {
    await this.$store.dispatch('automationEngine/clearActiveScheduler')
    await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('automationEngine/getMoveFieldComparisons'),
    ])

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getMoveScheduler', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setSchedulerValues()
      }
    }
    this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Content Move'
  },
  methods: {
    addIndexField(isLoading) {
        const isFound = this.indexFieldFound(this.dynamicField.fieldID)
        if (!isFound) {
            if (!isLoading) {
              this.isDirty = true
            }
            this.dynamicSubFolders.push(
             {
                name: this.dynamicField.name,
                id: this.dynamicField.fieldID,
                value: this.dynamicField.value,
             },
            )
        }
    },
    cancel() { this.$router.push({ name: 'AutomationEngineContentMove' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true && this.fileBrowseField !== '') {
        // Set folder info here, including ID and name
        if (this.fileBrowseField === 'folder') {
            this.isDirty = true
            this.folderId = this.selectedFolder.item.FolderId
            this.folderName = this.selectedFolder.item.Name
        } else {
            this.destinationFolderId = this.selectedFolder.item.FolderId
            this.destinationFolder = this.selectedFolder.item.Name
        }
      } else {
        this.folderId = null
        this.folderName = ''
      }

      this.toggleBrowseFolderDialog()
    },
    hideSnackbar() { this.showSnackbar = false },
    indexFieldFound(id) {
        let isFound = false
        this.dynamicSubFolders.forEach(e => {
            if (e.id === id) {
                isFound = true
            }
        })
        return isFound
    },
    removeIndexField(id) {
        const isFound = this.indexFieldFound(id)
        if (isFound) {
            this.isDirty = true
            this.dynamicSubFolders = this.dynamicSubFolders.filter(e => {
            return e.id !== id
        })
        }
    },
    async save() {
      if (!this.applyToSingleFolder) {
          this.folderId = null
          this.folderName = ''
      }
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          applyOnAllFolders: this.applyToAllFolders,
          includeSubfolders: this.includeSubfolders,
          fromFolderID: this.folderId,
          fromFolderName: this.folderName,
          toFolderID: this.destinationFolderId,
          toFolderName: this.destinationFolder,
          createNewSubfolder: this.newSubfolder,
          subfolderName: this.newSubfolderName,
          moveSelectedSubfolderAndContents: this.moveSubfolder,
          moveWithOCROnly: this.moveWithOCR,
          attributeFormID: this.attributeForm.value,
          compareFieldName: this.compareField.value,
          compareFieldCriteria: this.compareFieldCriteria.value,
          compareFieldValue: this.comparingValue,
          createDynamicSubfolders: this.createDynamicSubfolders,
          dynamicSubfolderFieldNames: this.dynamicSubFoldersData,
        }
        if (!await this.$store.dispatch('automationEngine/saveMoveScheduler', payload)) {
          const errorText = 'There was an error saving the content move.'
          this.snackbarTitle = 'ERROR'
          this.snackbarText = errorText
          this.showSnackbar = true
          this.snackbarImage = false
          console.warn(errorText)
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'Content move saved successfully'
          this.showSnackbar = true
          this.snackbarImage = true
          this.$router.push({ name: 'AutomationEngineContentMove' })
        }
      }
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setSchedulerValues() {
      this.schedulerId = this.activeScheduler.runDetails?.schedulerID
      this.applyToType = this.activeScheduler.applyOnAllFolders ? 'all' : 'single'
      this.includeSubfolders = this.activeScheduler.includeSubfolders
      this.folderId = this.activeScheduler.fromFolderID
      this.folderName = this.activeScheduler.fromFolderName
      this.destinationFolderId = this.activeScheduler.toFolderID
      this.destinationFolder = this.activeScheduler.toFolderName
      this.newSubfolder = this.activeScheduler.createNewSubfolder
      this.newSubfolderName = this.activeScheduler.subfolderName
      this.moveSubfolder = this.activeScheduler.moveSelectedSubfolderAndContents
      this.moveWithOCR = this.activeScheduler.moveWithOCROnly
      this.attributeForm = this.attributeFormList.find(a => a.value === this.activeScheduler.attributeFormID) || ''
      this.compareField = this.attributeFieldList.find(a => a.value === this.activeScheduler.compareFieldName) || ''
      this.compareFieldCriteria = this.comparisonFormList.find(a => a.value === this.activeScheduler.compareFieldCriteria) || ''
      this.comparingValue = this.activeScheduler.compareFieldValue
      this.createDynamicSubfolders = this.activeScheduler.createDynamicSubfolders
      this.activeScheduler.dynamicSubfolderFieldNames?.forEach(e => {
          this.dynamicField = this.attributeFieldList.find(a => a.value === e) || ''
          this.addIndexField(true)
      })
    },
    toggleBrowseFolderDialog(field) {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(id) {
      this.isDirty = true
      this[id] = !this[id]
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = 'ERROR: '

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors}`
      }
      // Any other validation for this component goes here ...
      if (!this.folderName && this.applyToSingleFolder) {
          error = true
          errorText = 'Please select a folder to move from'
      }
      if (this.applyToSingleFolder && this.folderId === this.destinationFolderId)
      {
        error = true
        errorText = 'The move from folder and destination folder cannot be the same.'
      }
      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #content-move-details {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 390px;
        }
      }

      .browse-input {
        margin-right: 5px;
      }

      .vasion-button {
        &.browse-button {
          button {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 13px;
          }
        }
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 100%;

      .subheader {
        @include SubHeadline;
        width: 225px;
        display: block;
        float: left;
        margin-top: 5px;
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }
  .align-values {
      display: flex;
      flex-direction: row;
      align-items: center;
  }

  .image-column-end {
    max-width: 100px;
    width: 100px;
    fill: $grey-400;
    padding-right: 15px;
  }
  .table-width {
      width: 500px;
  }
  .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
